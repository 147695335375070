import { useState, useEffect } from 'react';
import { Row, Col, DatePicker, Empty, Spin, Tooltip, Button } from 'antd';
import { useAxios } from '../../util/AxiosUtil';
import { Column } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { each, groupBy } from '@antv/util';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const BuildingDailyConsumeChart = ({ buildingId, sensorTypes, purpose }) => {

  const dateFormat = "YYYY-MM-DD";
  const dateFormatDisp = "DD.MM.YYYY";

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const [graphData, setGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [currentDate, setCurrentDate] = useState(dayjs().format(dateFormat));
  const [isLoading, setIsLoading] = useState(true);
  const [purposeColor, setPurposeColor] = useState([]);

  useEffect(() => {
    customFetch(currentDate);
    setPurposeColor(purpose === 'PLN' ? '#FAA219' : purpose === 'EL' ? '#97572b' : purpose === 'TV' ? '#cc0000' : '#1ca9e6');
  }, [buildingId]);

  const customFetch = async (startDate) => {
      setIsLoading(true);

      try {
        const domat = (sensorTypes.some(type => type['sensorType'] === 'DOMAT' && type['purpose'] === purpose)) ?
            await axiosClient.get(process.env.REACT_APP_DOMAT_DATA_PATH+'/consume/building/daily/'+buildingId+'?purpose='+purpose+'&date='+startDate) : null;

        const elsys = (sensorTypes.some(type => type['sensorType'] === 'ELSYS' && type['purpose'] === purpose)) ?
            await axiosClient.get(process.env.REACT_APP_ELSYS_DATA_PATH+'/consume/building/daily/'+buildingId+'?purpose='+purpose+'&date='+startDate) : null;
        
        const zenner = (sensorTypes.some(type => type['sensorType'] === 'ZENNER' && type['purpose'] === purpose)) ?
            await axiosClient.get(process.env.REACT_APP_ZENNER_DATA_PATH+'/consume/building/daily/'+buildingId+'?purpose='+purpose+'&date='+startDate) : null;
        
        const sharky = (sensorTypes.some(type => type['sensorType'] === 'SHARKY' && type['purpose'] === purpose)) ?
            await axiosClient.get(process.env.REACT_APP_SHARKY_DATA_PATH+'/consume/building/daily/'+buildingId+'?purpose='+purpose+'&date='+startDate) : null;

        var domatData = [];
        if (sensorTypes.some(type => type['sensorType'] === 'DOMAT' && type['purpose'] === purpose)) {
          for (var d = 0; d < domat.data.length; d++) {
            domatData.push({
              unit: domat.data[d].unit,
              value: parseFloat(domat.data[d].value),
              purpose: domat.data[d].purpose === 'PLN' ? 'Plyn' : domat.data[d].purpose === 'EL' ? 'Elektrina' : 'Voda',
              date: dayjs(domat.data[d].date).format(dateFormatDisp),
              //type: 'DOMAT'
            });
          }
        }

        var elsysData = [];
        if (sensorTypes.some(type => type['sensorType'] === 'ELSYS' && type['purpose'] === purpose)) {
          for (var k = 0; k < elsys.data.length; k++) {
            elsysData.push({
              unit: elsys.data[k].unit,
              value: parseFloat(elsys.data[k].value),
              purpose: elsys.data[k].purpose === 'PLN' ? 'Plyn' : elsys.data[k].purpose === 'EL' ? 'Elektrina' : 'Voda',
              date: dayjs(elsys.data[k].date).format(dateFormatDisp),
              //type: 'ELSYS'
            });
          }
        }

        var zennerData = [];
        if (sensorTypes.some(type => type['sensorType'] === 'ZENNER' && type['purpose'] === purpose)) {
          for (var z = 0; z < zenner.data.length; z++) {
            zennerData.push({
              unit: zenner.data[z].unit,
              value: parseFloat(zenner.data[z].value),
              purpose: zenner.data[z].purpose === 'PLN' ? 'Plyn' : zenner.data[z].purpose === 'EL' ? 'Elektrina' : 'Voda',
              date: dayjs(zenner.data[z].date).format(dateFormatDisp),
              //type: 'ZENNER'
            });
          }
        }

        var sharkyData = [];
        if (sensorTypes.some(type => type['sensorType'] === 'SHARKY' && type['purpose'] === purpose)) {
          for (var s = 0; s < sharky.data.length; s++) {
            sharkyData.push({
              unit: sharky.data[s].unit,
              value: parseFloat(sharky.data[s].value),
              purpose: sharky.data[s].purpose === 'PLN' ? 'Plyn' : sharky.data[s].purpose === 'EL' ? 'Elektrina' : 'Voda',
              date: dayjs(sharky.data[s].date).format(dateFormatDisp),
              //type: 'SHARKY'
            });
          }
        }

        //let mergedData = [...elsysData, ...domatData, ...zennerData, ...sharkyData];
//        if (domatData.length > elsysData.length)
//          finalData = [...domatData, ...elsysData, ...zennerData, ...sharkyData];

        const finalData = Object.values([...elsysData, ...domatData, ...zennerData, ...sharkyData].reduce((acc, { date, value }) => {
          acc[date] = { date, value: (acc[date] ? acc[date].value : 0) + value };
          return acc;
        }, {}));

        setEmptyGraphData(false);
        if (finalData.length === 0)
          setEmptyGraphData(true);

        setGraphData(finalData);
        setIsLoading(false);
      } catch(err) {
        console.log(err);
      }
  };

  const getSensorUnit = () => {
    return purpose === 'PLN' ? 'm3' : purpose === 'EL' ? 'kWh' : 'm3'
  };

  function onDateChange(date) {
    var changedDate = dayjs(date).format(dateFormat);
    if (date === null)
      changedDate = dayjs().format(dateFormat);
    
    setCurrentDate(changedDate);
    customFetch(changedDate);
  }

  const prevStep = () => {
    setCurrentDate(dayjs(currentDate).subtract(1,'d').format('YYYY-MM-DD'));
    customFetch(dayjs(currentDate).subtract(1,'d').format('YYYY-MM-DD'));
  }
  
  const nextStep = () => {
    if (!dayjs(currentDate).isSame(dayjs().format('YYYY-MM-DD'))) {
      setCurrentDate(dayjs(currentDate).add(1,'d').format('YYYY-MM-DD'));
      customFetch(dayjs(currentDate).add(1,'d').format('YYYY-MM-DD'));
    } else {
      customFetch(currentDate);
    }
  }

  const annotations = [];
/*
  if (sensorTypes.filter((obj) => obj.purpose === purpose).length > 1) {
    each(groupBy(graphData, 'date'), (values, k) => {
      const value = values.reduce((a, b) => a + b.value, 0);
      annotations.push({
        type: 'text',
        position: [k, value],
        content: `${value}`,
        style: {
          textAlign: 'center',
          fontSize: 12,
          fill: 'rgba(0,0,0,0.85)',
        },
        offsetY: -10,
      });
    });
  }
*/
  const config = {
    data: graphData,
    xField: 'date',
    yField: 'value',
    //isStack: true,
    //seriesField: 'type',
    color: purposeColor,
    height: 200,
    legend: false,
    tooltip: {
      formatter: (x) => {
        return { name: 'Spotreba', value: parseFloat(x.value).toFixed(3) + ' ' + getSensorUnit()};
      },
    },
    label: {
      position: 'middle',
      content: function content(item) {
        return parseFloat(item.value).toFixed(3);
      }
    },
    //annotations
  };

  return (
    <Spin spinning={isLoading}>
      <Row style={{ paddingBottom: 20 }}>
        <Col flex={3}>
          <Row>
            <Col>
              <DatePicker value={dayjs(currentDate, dateFormat)} onChange={onDateChange} format="DD.MM.YYYY" />
            </Col>
          </Row>
        </Col>
        <Col flex={2}>
          <Row justify="end">
            <Col style={{ paddingRight: 5 }}>
              <Tooltip style={{ margin: 15 }} >
                <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
              </Tooltip>
            </Col>
            <Col style={{ paddingRight: 5 }}>
              <Tooltip style={{ margin: 15 }} >
                <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          {emptyGraphData ?
            <Empty style={{height: 200}}/>
          :
            <ReactG2Plot Ctor={Column} options={config} />
          }
        </Col>
      </Row>
    </Spin>
  );
};

export default BuildingDailyConsumeChart;