import { useState, useEffect } from 'react';
import { useAxios } from '../../../util/AxiosUtil';
import { Line } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { Row, Col, DatePicker, Button, Tooltip, Empty } from 'antd';
import { LeftOutlined, RightOutlined, SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const MeasureGraphEnergy = (params) => {

    const dateFormat = "YYYY-MM-DD";
    const dateFormatDisp = "DD.MM.YYYY";
    const dateTimeFromat = "DD.MM.YYYY HH:mm:ss";

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const [graphData, setGraphData] = useState([]);
    const [emptyGraphData, setEmptyGraphData] = useState(false);
    const [currentDate, setCurrentDate] = useState(dayjs().format(dateFormat));
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      customFetch({sensorId: params.sensorId, createdOn: dayjs().format(dateFormat)});
    }, []);

    const customFetch = async (searchCriteria = {}) => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_SHARKY_DATA_PATH+'/measureLog', {
        params: searchCriteria
      }).then(response => {

        setEmptyGraphData(false);
        if (response.data.length === 0)
          setEmptyGraphData(true);

        response.data.sort(function(a,b){
          var dateA = new Date(a.createdOn).getTime();
          var dateB = new Date(b.createdOn).getTime();
          return dateA > dateB ? 1 : -1;  
        });

        var myJson = [];

        for (var k = 0; k < response.data.length; k++) {
          myJson.push({
            name: 'Energy',
            unit: response.data[k].energyUnit,
            value: parseFloat(response.data[k].energy),
            createdOn: dayjs(response.data[k].createdOn).format(dateTimeFromat)
          },{
            name: 'Volume',
            unit: response.data[k].volumeUnit,
            value: parseFloat(response.data[k].volume),
            createdOn: dayjs(response.data[k].createdOn).format(dateTimeFromat)
          },{
            name: 'Flow',
            unit: response.data[k].flowUnit,
            value: parseFloat(response.data[k].flow),
            createdOn: dayjs(response.data[k].createdOn).format(dateTimeFromat)
          },{
            name: 'Power',
            unit: response.data[k].powerUnit,
            value: parseFloat(response.data[k].power),
            createdOn: dayjs(response.data[k].createdOn).format(dateTimeFromat)
          });
        }

        setGraphData(myJson);
        setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    function onDateChange(date) {
      var changedDate = dayjs(date).format(dateFormat);
      if (date === null)
        changedDate = dayjs().format(dateFormat);
      
      setCurrentDate(changedDate);
      customFetch({sensorId: params.sensorId, createdOn: changedDate});
    }

    const prevStep = () => {

      var new_date = dayjs(currentDate).add(-1, 'days')
      var changedDate = new_date.format(dateFormat);
      setCurrentDate(changedDate);

      customFetch({sensorId: params.sensorId, createdOn: changedDate});
    }
    
    const nextStep = () => {

        var new_date = dayjs(currentDate).add(1, 'days')
        var changedDate = new_date.format(dateFormat);
        setCurrentDate(changedDate);

        customFetch({sensorId: params.sensorId, createdOn: changedDate});
    }

    const refresh = () => {
      customFetch({sensorId: params.sensorId, createdOn: currentDate});
    }

    const config = {
      data: graphData,
      xField: 'createdOn',
      yField: 'value',
      seriesField: 'name',
      point: {
        size: 5,
        shape: 'diamond',
      },
      tooltip: {
        customContent: (title, data) => {
          var styledData = '';
          for (var k = 0; k < data.length; k++) {
            styledData += `<li class="g2-tooltip-list-item" data-index="" style="list-style-type: none; padding: 0px; margin: 12px 0px;">
              <span class="g2-tooltip-marker" style="background: ${data[k].color} none repeat scroll 0% 0%; width: 8px; height: 8px; border-radius: 50%; display: inline-block; margin-right: 8px;"></span>
              <span class="g2-tooltip-name">${data[k].name}</span>:
              <span class="g2-tooltip-value" style="display: inline-block; float: right; margin-left: 30px;">${data[k].value} ${data[k].data.unit}</span>
            </li>`;
          }

          return `<div class="g2-tooltip-title" style="margin-bottom: 12px; margin-top: 12px;">${title}</div>
          <ul class="g2-tooltip-list" style="margin: 0px; list-style-type: none; padding: 0px;">
            ${styledData}
          </ul>`;
        }
      },
      label: {
        style: {
          fill: '#aaa',
        },
      },
      colorField:'name',
      color: ({ name }) => {
        if (name === 'Flow'){
          return '#1979C9';
        }
        if (name === 'Power'){
          return '#D62A0D';
        }
        if (name === 'Energy'){
          return '#FAA219';
        }
        if (name === 'Volume'){
          return '#5AD8A6';
        }
      }
    };

    return (
      <div>
        <Row style={{ paddingBottom: 20 }}>
          <Col flex={3}>
            <Row>
              <Col>
                <DatePicker value={dayjs(currentDate, dateFormat)} onChange={onDateChange} format={dateFormatDisp} />
              </Col>
            </Row>
          </Col>
          <Col flex={2}>
            <Row justify="end">
              <Col style={{ paddingRight: 5 }}>
                <Tooltip title="Previous" style={{ margin: 15 }} >
                  <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                </Tooltip>
              </Col>
              <Col style={{ paddingRight: 5 }}>
                <Tooltip title="Next" style={{ margin: 15 }} >
                  <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                </Tooltip>
              </Col>
              <Col style={{ paddingRight: 5 }}>
                <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refresh} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            {emptyGraphData ?
              <Empty />
            :
              <ReactG2Plot Ctor={Line} options={config} />
            }
          </Col>
        </Row>
      </div>
    );
};

export default MeasureGraphEnergy;