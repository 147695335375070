import { useState, useEffect } from 'react';
import { Row, Col, DatePicker, Empty, Spin, Button, Tooltip } from 'antd';
import { useAxios } from '../../../../util/AxiosUtil';
import { Line } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const ElsysDailyConsumeChart = ({ sensorName, purpose }) => {

  const dateFormat = "YYYY-MM-DD";
  const dateFormatDisp = "DD.MM.YYYY";

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const [graphData, setGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [currentDate, setCurrentDate] = useState(dayjs().format(dateFormat));
  const [isLoading, setIsLoading] = useState(true);
  const [purposeColor, setPurposeColor] = useState([]);

  useEffect(() => {
    customFetch(currentDate);
    setPurposeColor(purpose === 'PLN' ? '#FAA219' : purpose === 'EL' ? '#97572b' : purpose === 'TV' ? '#cc0000' : '#1ca9e6');
  }, [sensorName]);

  const customFetch = async (startDate) => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ELSYS_DATA_PATH+'/consume/sensor/daily/'+sensorName+'?date='+startDate)
      .then(response => {

        setEmptyGraphData(false);
        if (response.data.length === 0)
          setEmptyGraphData(true);

        response.data.sort(function(a,b){
          var dateA = new Date(a.date).getTime();
          var dateB = new Date(b.date).getTime();
          return dateA > dateB ? 1 : -1;  
        });
        
        var myJson = [];
        for (var k = 0; k < response.data.length; k++) {
          if (response.data[k].value !== null) {
            myJson.push({
              unit: response.data[k].unit,
              value: parseFloat(response.data[k].value),
              date: dayjs(response.data[k].date).format(dateFormatDisp)
            });
          }
        }

        setGraphData(myJson);
        
        setIsLoading(false);
      }).catch('error: ' + console.log);
  };

  const getSensorUnit = () => {
    return purpose === 'PLN' ? 'm3' : purpose === 'EL' ? 'kWh' : 'm3'
  };

  function onDateChange(date) {
    var changedDate = dayjs(date).format(dateFormat);
    if (date === null)
      changedDate = dayjs().format(dateFormat);
    
    setCurrentDate(changedDate);
    customFetch(changedDate);
  }

  const prevStep = () => {
    setCurrentDate(dayjs(currentDate).subtract(1,'d').format('YYYY-MM-DD'));
    customFetch(dayjs(currentDate).subtract(1,'d').format('YYYY-MM-DD'));
  }
  
  const nextStep = () => {
    if (!dayjs(currentDate).isSame(dayjs().format('YYYY-MM-DD'))) {
      setCurrentDate(dayjs(currentDate).add(1,'d').format('YYYY-MM-DD'));
      customFetch(dayjs(currentDate).add(1,'d').format('YYYY-MM-DD'));
    } else {
      customFetch(currentDate);
    }
  }

  const config = {
    data: graphData,
    xField: 'date',
    yField: 'value',
    color: purposeColor,
    height: 200,
    label: {},
    tooltip: {
      formatter: (x) => {
        return { name: 'Spotreba', value: x.value + ' ' + getSensorUnit() };
      },
    },
    point: {
      size: 5,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: purposeColor,
        lineWidth: 2,
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };

  return (
    <Spin spinning={isLoading}>
      <Row style={{ paddingBottom: 20 }}>
        <Col flex={3}>
          <Row>
            <Col>
              <DatePicker value={dayjs(currentDate, dateFormat)} onChange={onDateChange} format="DD.MM.YYYY" />
            </Col>
          </Row>
        </Col>
        <Col flex={2}>
          <Row justify="end">
            <Col style={{ paddingRight: 5 }}>
              <Tooltip style={{ margin: 15 }} >
                <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
              </Tooltip>
            </Col>
            <Col style={{ paddingRight: 5 }}>
              <Tooltip style={{ margin: 15 }} >
                <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          {emptyGraphData ?
            <Empty />
          :
            <ReactG2Plot Ctor={Line} options={config} />
          }
        </Col>
      </Row>
    </Spin>
  );
};

export default ElsysDailyConsumeChart;