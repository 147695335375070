import { useState, useEffect } from 'react';
import { useAxios } from '../../../util/AxiosUtil';
import { Bar } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';

const LastMeasureGraph = (params) => {
  
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const [graphData, setGraphData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      customFetch();
    }, [params.sensorId]);

    const customFetch = async () => {
        setIsLoading(true);
        await axiosClient.get(process.env.REACT_APP_SHARKY_DATA_PATH+'/measure/'+params.sensorId)
        .then(response => {
          
          var myJson = [];
          if (response.data !== "") {
            myJson.push({
                type: 'Energy' + " (" + response.data.energyUnit + ")",
                value: parseFloat(response.data.energy)
              },{
                type: 'Power' + " (" + response.data.powerUnit + ")",
                value: parseFloat(response.data.power)
              },{
                type: 'Volume' + " (" + response.data.volumeUnit + ")",
                value: parseFloat(response.data.volume)
              },{
                type: 'Flow' + " (" + response.data.flowUnit + ")",
                value: parseFloat(response.data.flow)
              },{
                type: 'RtTemp' + " (" + response.data.rtTempUnit + ")",
                value: parseFloat(response.data.rtTemp)
              },{
                type: 'FwTemp' + " (" + response.data.fwTempUnit + ")",
                value: parseFloat(response.data.fwTemp)
              }
            );
          }

          setGraphData(myJson);
          setIsLoading(false);
        }).catch('error: ' + console.log);
    };

    var config = {
        data: graphData,
        xField: 'value',
        height: 200,
        yField: 'type',
        label: {
          position: 'middle',
          style: {
            fill: '#FFFFFF',
            opacity: 0.6,
          },
        }
    };

    return (
        <ReactG2Plot Ctor={Bar} options={config} />
    );
};

export default LastMeasureGraph;