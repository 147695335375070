import { useState, useEffect } from 'react';
import { Row, Col, DatePicker, Empty, Spin, Tooltip, Button } from 'antd';
import { useAxios } from '../../../../util/AxiosUtil';
import { Line } from '@antv/g2plot';
import ReactG2Plot from 'react-g2plot';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const UnitZennerMonthlyConsumeChart = ({ unitId }) => {

  const dateFormat = "YYYY-MM";
  const dateFormatDisp = "MM/YYYY";

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const [graphData, setGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [currentDate, setCurrentDate] = useState(dayjs().format(dateFormat));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    customFetch(currentDate);
  }, [unitId]);

  const customFetch = async (startDate) => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ZENNER_DATA_PATH+'/consume/unit/monthly/'+unitId+'?date='+startDate)
      .then(response => {

        setEmptyGraphData(false);
        if (response.data.length === 0)
          setEmptyGraphData(true);

        response.data.reverse();
        var myJson = [];
        for (var k = 0; k < response.data.length; k++) {
          myJson.push({
            unit: response.data[k].unit,
            value: parseFloat(response.data[k].value),
            purpose: response.data[k].purpose === 'TV' ? 'Teplá voda' : 'Studená voda',
            date: dayjs(response.data[k].date).format(dateFormatDisp)
          });
        }

        setGraphData(myJson);

        setIsLoading(false);
      }).catch('error: ' + console.log);
  };

  function onDateChange(date) {
    var changedDate = dayjs(date).format(dateFormat);
    if (date === null)
      changedDate = dayjs().format(dateFormat);
    
    setCurrentDate(changedDate);
    customFetch(changedDate);
  }
  
  const prevStep = () => {
    setCurrentDate(dayjs(currentDate).subtract(1,'M').format('YYYY-MM'));
    customFetch(dayjs(currentDate).subtract(1,'M').format('YYYY-MM'));
  }
  
  const nextStep = () => {
    if (!dayjs(currentDate).isSame(dayjs().format('YYYY-MM'))) {
      setCurrentDate(dayjs(currentDate).add(1,'M').format('YYYY-MM'));
      customFetch(dayjs(currentDate).add(1,'M').format('YYYY-MM'));
    } else {
      customFetch(currentDate);
    }
  }

  const config = {
    data: graphData,
    xField: 'date',
    yField: 'value',
    seriesField: 'purpose',
    stepType: 'hvh',
    color: ['#cc0000', '#1ca9e6'],
    height: 200,
    tooltip: {
      formatter: (x) => {
        return { name: x.purpose, value: x.value + ' m3' };
      },
    },
    label: {
      content: function content(item) {
        return ''.concat(item.value, ' m3');
      }
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Row style={{ paddingBottom: 20 }}>
        <Col flex={3}>
          <Row>
            <Col>
              <DatePicker value={dayjs(currentDate, dateFormat)} onChange={onDateChange} format="MM.YYYY" picker="month" />
            </Col>
          </Row>
        </Col>
        <Col flex={2}>
          <Row justify="end">
            <Col style={{ paddingRight: 5 }}>
              <Tooltip style={{ margin: 15 }} >
                <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
              </Tooltip>
            </Col>
            <Col style={{ paddingRight: 5 }}>
              <Tooltip style={{ margin: 15 }} >
                <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          {emptyGraphData ?
            <Empty />
          :
            <ReactG2Plot Ctor={Line} options={config} />
          }
        </Col>
      </Row>
    </Spin>
  );
};

export default UnitZennerMonthlyConsumeChart;